import { createGlobalStyle } from 'styled-components';
import { accent } from 'constants/theme';
/*
 * Fix for font flickering on React component updates
 * See: https://github.com/gatsbyjs/gatsby/issues/9826#issuecomment-442303407
 * TODO: Find a solution or remove this approach altogether and hardcode going forward
import fontFiles from 'styles/fonts';

  @font-face {
    font-family: "League Gothic";
    font-style: italic;
    font-weight: normal;
    src: url(${fontFiles.LeagueGothicItalicEOT}) format("eot");
    src: url(${fontFiles.LeagueGothicItalicTTF}) format("truetype"),
    url(${fontFiles.LeagueGothicItalicWOFF}) format("woff");
  }

  @font-face {
    font-family: "Vincent";
    font-style: normal;
    font-weight: normal;
    src: local("Vincent"),
    local("Vincent"),
    url(${fontFiles.VincentRegularEOT}) format("eot"),
    url(${fontFiles.VincentRegularTTF}) format("truetype"),
    url(${fontFiles.VincentRegularWOFF}) format("woff");
  }
*/
export default createGlobalStyle`

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body {
    font-family: Montserrat, Oswald,-apple-system,BlinkMacSystemFont,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    line-height: 1;
    font-size: 1rem;
    color: #000;
    background-color: #000;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-font-feature-settings: "pnum";
    font-feature-settings: "pnum";
    font-variant-numeric: proportional-nums;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  a {
    color: ${accent};
    text-decoration: none;
  }
  a:visited, a:hover {
    text-decoration: none;
  }

  pre {
    display: block;
    padding: 2rem;
    margin-top: 4rem;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    border-radius: 5px;
    color: ${accent};
    border: 1px solid #ddd;
    font-family: "SFMono-Regular",Consolas,"Liberation Mono",Menlo,Courier,monospace;
  }

  video {
    max-width: 100%;
  }

  input, select, textarea, button {
    font-family: 'Montserrat';
  }

  // MBO Widget styling
  .bw-widget__header {
    background: none
  }

  .bw-session__availability,
  .bw-session__meta {
    display: none
  }
`;
