import React from 'react';

import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import posed from 'react-pose';
import { Box } from '@rebass/grid';

import Logo from 'components/atoms/logo';
import ScrollButton from 'components/atoms/scroll-button';
import { Consumer } from 'store/createContext';
import { mq } from 'styles/breakpoints';

// Example of a component-specific page transition
const AnimatedContainer = posed.div({
  enter: {
    y: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    y: '-100%',
    transition: {
      ease: 'easeInOut',
    },
  },
});

const Header = ({ bgColor, logoImg }) => {
  // This is to differentiate the regular header used in the templates and the corporate header
  const corporateHeader = bgColor == 'white';
  // the `unset` value comes from https://stackoverflow.com/questions/5218679/how-can-i-nullify-css-property
  const displayButton = corporateHeader ? 'unset' : 'none';
  const display = corporateHeader ? 'grid' : 'block';

  let container = css`
    justify-content: space-between;
    align-items: center;
    text-align: center;
    background: ${bgColor};
    display: ${display};
    grid-template-columns: repeat(10, 10%);
    padding: 0.5rem;
  `;

  let logoCss = css`
    width: 125px;
    margin-left: 1rem;

    ${mq.desktop} {
      margin: 1.5rem auto;
      width: 150px;
    }
    grid-column: span 3;
  `;

  let buttonHeader = css`
    grid-column-start: 6;
    display: ${displayButton};
    ${mq.desktop} {
      grid-column-start: 8;
    }
  `;
  return (
    <AnimatedContainer>
      <Consumer>
        {({ theme }) => (
          <Box as="header" width={1} css={container} theme={theme}>
            <Logo logoStyling={logoCss} logoType={logoImg} />
            <ScrollButton
              text="BUILD MY TEAM PACKAGE"
              wrapperStyling={buttonHeader}
            />
          </Box>
        )}
      </Consumer>
    </AnimatedContainer>
  );
};

Header.propTypes = {
  bgColor: PropTypes.string,
  logoImg: PropTypes.string,
};

Header.defaultProps = {
  bgColor: 'transparent',
};

export default Header;
