export const colors = {
  black: '#000',
  gray: '#6d6e70',
  grayRgb: '109, 110, 112',
  offWhite: '#fafafa',
  red: '#cd2331',
  transparent: 'transparent',
  white: '#fff',
};

export const componentHeights = {
  navbar: {
    main: '3rem',
    large: '5rem',
  },
};

export const spacing = {
  margin: {
    mobile: '2rem',
    tablet: '3rem',
    desktop: '4rem',
    widescreen: '8rem',
  },
  padding: {
    mobile: '2rem',
    tablet: '3rem',
    desktop: '4rem',
    widescreen: '8rem',
  },
};
