import React from 'react';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import 'react-table/react-table.css';

import Layout from 'components/layout';
import Header from 'components/header';
import PageMeta from 'components/page-meta';
import { ContentBox } from 'styles/boxes.css';
import { H2 } from 'styles/headers.css';
import { P } from 'styles/text.css';

const DashboardTemplate = ({ pageTitle, pageDescription, summaryData }) => {
  let allLeads;
  try {
    const campaignleads = summaryData.map(c => c.leads);
    allLeads = campaignleads.reduce((a, b) => [...a, ...b], []);
  } catch {
    allLeads = [];
  }
  // See: https://stackoverflow.com/a/43455982/1118796

  const summaryColumns = [
    {
      Header: 'Name',
      accessor: 'name',
      /* eslint-disable-next-line */
      Cell: d => <span className="number">{d.value}</span>,
      minWidth: 125,
      Footer: (
        <span>
          <strong>Totals</strong>
        </span>
      ),
    },
    {
      id: 'leads',
      Header: 'Leads',
      accessor: d => d.leads.length,
      Footer: (
        <span>
          <strong>{allLeads.length}</strong>
        </span>
      ),
    },
    {
      id: 'sales',
      Header: 'Sales',
      accessor: d => d.leads.filter(l => l.isConverted).length,
      Footer: (
        <span>
          <strong>{allLeads.filter(l => l.isConverted).length}</strong>
        </span>
      ),
    },
  ];
  const detailColumns = [
    {
      Header: 'Email',
      accessor: 'email',
      minWidth: 150,
    },
    {
      id: 'converted',
      Header: 'Purchased',
      accessor: d => (d.isConverted ? 'Yes' : 'No'),
    },
  ];
  return (
    <Layout>
      <PageMeta title={pageTitle} description={pageDescription} />
      <ContentBox width={1} m="auto" p={[3, 4, 4, 4]}>
        <Header bgColor="black" />
        <H2>{pageTitle}</H2>
        <ReactTable
          data={summaryData}
          columns={summaryColumns}
          defaultPageSize={50}
          // No empty rows: https://github.com/react-tools/react-table/issues/514#issuecomment-332864703
          minRows={0}
          sorted={[
            {
              id: 'sales',
              desc: true,
            },
            {
              id: 'leads',
              desc: true,
            },
          ]}
          SubComponent={row => {
            const detailData = row.original.leads;
            return (
              <div>
                <P>{row.original.name}</P>
                <ReactTable
                  data={detailData}
                  columns={detailColumns}
                  defaultPageSize={10}
                  minRows={0}
                  sorted={[
                    {
                      id: 'converted',
                      desc: true,
                    },
                    {
                      id: 'email',
                      desc: false,
                    },
                  ]}
                />
              </div>
            );
          }}
        />
      </ContentBox>
    </Layout>
  );
};

DashboardTemplate.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  pageDescription: PropTypes.string.isRequired,
  summaryData: PropTypes.object.isRequired,
  summaryColumns: PropTypes.array.isRequired,
  detailColumns: PropTypes.array.isRequired,
};

export default DashboardTemplate;
