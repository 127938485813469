import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

/*
 * Render a logo file using Gatsby image with a static query.
 * Right now, there is only one image file in the project.
 * In the future, add support for multiple image files by mapping
 * the `logoType` prop with the relevant GraphQL query.
 */
const LogoWithQuery = ({ logoStyling }) => (
  <StaticQuery
    query={graphql`
      query {
        blackLetterRedMark: file(name: { eq: "gwx-logo-full.original" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <Img
        css={logoStyling}
        fluid={data.blackLetterRedMark.childImageSharp.fluid}
      />
    )}
  />
);

LogoWithQuery.propTypes = {
  logoStyling: PropTypes.object,
};

const Logo = ({ logoStyling, logoType }) => {
  if (logoType === 'blackLetters') {
    return <LogoWithQuery logoStyling={logoStyling} />;
  } else if (logoType === 'whiteLetters') {
    return <img css={logoStyling} src="/logo.svg" alt="Gloveworx" />;
  } else {
    return <img css={logoStyling} src="/logo.svg" alt="Gloveworx" />;
  }
};

Logo.propTypes = {
  logoStyling: PropTypes.object,
  logoType: PropTypes.string,
};

Logo.defaultProps = {
  logoStyling: null,
  logoType: null,
};

export default Logo;
