import React from 'react';
import PropTypes from 'prop-types';

import { animateScroll } from 'react-scroll';
import { css } from '@emotion/core';

import { buttonHeavy } from 'styles/button.css';

const button = css`
  ${buttonHeavy()};
  margin-top: auto;
  margin-bottom: auto;
`;
function scrollToBottom() {
  animateScroll.scrollToBottom();
}

const ScrollButton = ({ text, wrapperStyling }) => {
  return (
    <div css={wrapperStyling}>
      <button
        onClick={() => {
          scrollToBottom();
        }}
        css={button}
      >
        {text}
      </button>
    </div>
  );
};

ScrollButton.propTypes = {
  text: PropTypes.string,
  wrapperStyling: PropTypes.object,
};

ScrollButton.defaultProps = {
  wrapperStyling: null,
};

export default ScrollButton;
