import { css } from '@emotion/core';
import { border, bgColor, textColor } from './text.css';

import { mq } from './breakpoints';

export const button = (
  color = 'black',
  colorh = 'white',
  backgroundColor = 'transparent',
  backgroundColorh = 'red'
) => {
  return css`
    transition: all 0.35s ease-in-out;
    cursor: pointer;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    color: white;
    height: 3rem;
    padding-left: 1.5em;
    padding-right: 1.5em;
    font-weight: bold;
    font-size: 0.5rem;
    ${textColor[color]};
    ${bgColor[backgroundColor]};
    ${border('red', '2px')};

    &:hover {
      ${textColor[colorh]};
      ${bgColor[backgroundColorh]};
    }
    &:focus {
      outline: none;
    }

    ${mq.desktop} {
      padding-left: 1rem;
      padding-right: 1rem;
      text-align: center;
      white-space: nowrap;
      height: 3rem;
    }
  `;
};

export const buttonHeavy = (
  color = 'black',
  colorh = 'white',
  backgroundColor = 'transparent',
  backgroundColorh = 'red'
) => {
  return css`
    ${button(color, colorh, backgroundColor, backgroundColorh)};

    margin-top: 2rem;
    margin-bottom: 2rem;
    ${mq.desktop} {
      font-size: 0.8rem;
      padding: 0 2rem;
      margin-top: 1.5rem;
    }
    &:disabled {
      ${bgColor.white};
      ${textColor.red};
      cursor: not-allowed;
    }
  `;
};

export const submitHeavy = (color = 'black', backgroundColor = 'red') => {
  return css`
    ${buttonHeavy()};
    margin-top: 1rem;
    ${textColor[color]};

    &:hover {
      ${textColor.white};
      ${bgColor[backgroundColor]};
    }
  `;
};
