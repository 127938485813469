import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Box } from '@rebass/grid';

import { mq } from './breakpoints';
import { border, bgColor, textColor, textFormat } from './text.css';

const dynamiContentBox = ({
  backgroundColor = 'white',
  color = 'black',
  textForm = 'alignCenter',
}) => css`
  ${bgColor[backgroundColor]};
  ${textColor[color]};
  ${textFormat[textForm]};
`;

export const ContentBox = styled(Box)`
  ${dynamiContentBox};
`;

export const CorporateBox = styled(ContentBox)`
  ${bgColor.black};
  ${textColor.white};
`;

export const H1Box = styled(Box)`
  position: relative;
  z-index: 0;
  margin: 0 auto 0.75rem;
  padding: 5rem 0;
  ${mq.tablet} {
    margin: -2rem auto 0rem;
  }
`;

export const CutoutH1Box = styled(Box)`
  // ${bgColor.red};
  padding: 5rem 0;
  margin: 0 auto;
`;

export const CenteredBox = styled(Box)`
  position: relative;
  text-align: center;
`;

export const ImageBox = css`
  ${border('red', '15px')};
  border-top: none;
  border-bottom: none;
`;

export const AbsoluteBox = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

// TODO: Turn hr constants into a function
export const BottomImageHr = css`
  ${bgColor.red};
  height: 15px;
`;

export const ContentHr = styled.hr`
  ${bgColor.red};
  height: 3px;
`;

// TODO: Refactor above two to use `Hr`
export const Hr = ({
  color = 'white',
  height = '1px',
  width = '100%',
  margin = '0 0 calc(1.45rem - 1px)',
  opacity = 1,
}) => styled.hr`
  ${bgColor[color]}
  height: ${height};
  width: ${width};
  margin: ${margin};
  opacity: ${opacity};
`;

export const RadioHr = Hr({ color: 'white', margin: '1rem 0', opacity: '0.3' });

export const RedBox = css`
  z-index: 1;
  ${bgColor.black};
  padding: 1rem;
  ${border('red', '3px')};
  text-align: left;
  max-width: 45%;
  margin-top: -2rem;
  margin-bottom: auto;
  &:focus {
    outline: none;
  }
`;

export const lowLine = (
  backgroundColor,
  topMargin = '4rem',
  width = '30%'
) => css`
  width: ${width};
  height: 4px;
  margin: ${topMargin} auto 2rem;
  ${bgColor[backgroundColor]};

  ${mq.tablet} {
    margin-top: 4rem;
    margin-bottom: 3rem;
  }

  ${mq.desktop} {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;
