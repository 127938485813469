import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { css } from '@emotion/core';
import { Flex, Box } from '@rebass/grid';

import Head from 'components/head';
import GlobalStyle from 'global.css.js';
import { Consumer } from 'store/createContext';

let Main = css`
  width: 100%;
  height: 100vh;
`;

const Layout = ({ data, children }) => (
  <Consumer>
    {({ theme }) => (
      <Flex css={Main} theme={theme}>
        <GlobalStyle />
        <Head data={data} />
        <Box as="main" css={Main} theme={theme}>
          {children}
        </Box>
      </Flex>
    )}
  </Consumer>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
};

const LayoutWithQuery = props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            siteTitle
          }
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
);

LayoutWithQuery.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutWithQuery;
