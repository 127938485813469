import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const PageMeta = ({ title, description }) => {
  return (
    <Helmet>
      <meta content={title} name="apple-mobile-web-app-title" />
      <meta content={title} property="og:title" />
      <meta content={title} name="twitter:title" />
      <title>{title}</title>
      <meta content={description} name="description" />
      <meta content={description} property="og:description" />
      <meta content={description} name="twitter:description" />
    </Helmet>
  );
};

PageMeta.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default PageMeta;
