import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { mq } from './breakpoints';
import {
  fontFamily,
  textColor,
  textFormat,
  redKnockoutText,
  whiteKnockoutText,
} from './text.css';

const headerBase = css`
  ${fontFamily.oswald}
`;
const letterSpacing = css`
  letter-spacing: 0.05em;
`;

export const H1 = styled.h1`
  ${headerBase};
  ${textColor.white};
  ${textFormat.upperCase};
  ${letterSpacing};
  margin: 0;
  font-size: 3rem;
  ${mq.desktop} {
    font-size: 8rem;
  }
`;

export const H1Vincent = styled(H1)`
  ${fontFamily.vincent};

  line-height: 4rem;
  font-size: 8rem;

  ${mq.tablet} {
    font-size: 6rem;
    line-height: 3rem;
  }

  ${mq.desktop} {
    font-size: 9rem;
    line-height: 4rem;
  }
`;

export const CutoutH1 = styled(H1)`
  // ${textColor.transparent};
  ${redKnockoutText};
`;

export const KnockoutH1 = styled(H1)`
  ${whiteKnockoutText};
`;

export const FocusH1 = styled(H1)`
  ${textColor.red};
`;

export const H2 = styled.h2`
  ${headerBase};
  ${textColor.black};
  ${textFormat.upperCase};
  ${letterSpacing}
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.5;
  ${mq.desktop} {
    font-size: 4rem;
  }
`;

export const H2White = styled(H2)`
  ${textColor.white};
  margin-bottom: 0;
`;

export const H2Vincent = styled(H2)`
  ${fontFamily.vincent};
  ${textColor.white};
  margin-bottom: 0;

  font-size: 3.75rem;

  ${mq.tablet} {
    font-size: 3rem;
  }

  ${mq.desktop} {
    font-size: 3.5rem;
  }
`;

export const FocusH2 = styled(H2)`
  ${textColor.red};
`;

const dynamicH3 = ({
  color = 'white',
  textCase = 'upperCase',
  alignment = 'inherit',
  fontSize = '3rem',
  fontType = 'oswald',
}) => css`
  ${fontFamily[fontType]}
  ${textFormat[textCase]};
  ${textColor[color]};
  text-align: ${alignment};
  font-size: 2rem;
  margin-bottom: 0.5rem;
  ${letterSpacing}

  ${mq.desktop} {
    font-size: ${fontSize};
  }
`;

export const H3 = styled.h3`
  ${dynamicH3};
`;

export const FocusH3 = styled(H3)`
  ${textColor.red};
`;

export const H3Vincent = styled(H3)`
  ${fontFamily.vincent};
  font-size: 2rem;
  ${mq.tablet} {
    font-size: 1.75rem;
  }
  ${mq.desktop} {
    font-size: 1.5rem;
  }
`;

const studioCampaignHeaderBase = css`
 ${fontFamily.nunito}
  ${textFormat.headerBold}
  ${textFormat.upperCase}
  font-size: 1.2rem;
`;

export const H3YourTime = styled.h3`
  ${studioCampaignHeaderBase}
  padding-bottom: 1rem;
`;

export const LegendYourTime = styled.legend`
  ${studioCampaignHeaderBase}
`;

export const H2Black = styled(H1)`
  ${fontFamily.vincent};
  ${textColor.black};
  padding-top: 1rem;
  font-size: 2rem;
  ${mq.tablet} {
    padding-top: 0;
    font-size: 4rem;
  }
  ${mq.desktop} {
    font-size: 6.5rem;
  }
`;

export const FocusH3Vincent = styled(FocusH3)`
  ${fontFamily.vincent};
  ${mq.tablet} {
    margin-bottom: 1rem;
  }
`;

const dynamicH4 = ({
  color = 'white',
  textCase = 'upperCase',
  alignment = 'inherit',
  fontSize = '2rem',
  fontType = 'oswald',
}) => css`
  ${fontFamily[fontType]}
  ${textFormat[textCase]};
  ${textFormat.bold}
  font-size: 0.7rem;
  text-align: ${alignment};
  ${textColor[color]};
  margin-bottom: 0.5rem;
  ${letterSpacing};

  ${mq.desktop} {
    font-size: ${fontSize};
  }
`;

export const H4 = styled.h4`
  ${dynamicH4};
`;

export const FocusH4 = styled(H4)`
  ${textColor.red};
`;
